<template>
  <div class="tabs">
    <el-tabs v-model="tab"
             @tab-click="tabClick">
      <el-tab-pane v-for="tab in tabList"
                   :key="tab.value"
                   :label="tab.label"
                   :name="tab.value">
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { defineComponent, toRefs, reactive } from 'vue'

export default defineComponent({
  emits: ['tabChang'],
  components: {
  },
  props: {
    tabList: {
      type: Array,
      default: () => []
    },
    tabcurrent: {
      type: String,
      default: () => '0'
    }
  },
  setup (props, context) {
    const data = reactive({ // 定义变量
      tab: props.tabcurrent
    })
    function tabClick (tab, event) {
      context.emit('tabChang', data.tab)
    }
    return {
      ...toRefs(data),
      tabClick
    }
  }
})
</script>

<style lang="scss" scoped>
.tabs::v-deep(.el-tabs__nav) {
  margin-left: 20px;
  height: 60px;
  line-height: 60px;
}
.tabs::v-deep(.el-tabs__item.is-active) {
  font-weight: 600;
}
.tabs::v-deep(.el-tabs__nav-wrap)::after {
  background: #fff;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);
}
.tabs::v-deep(.el-tabs__nav-wrap) {
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);
}
</style>
